import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/common/style.scss'
import '@/common/iconfont/iconfont'
import 'element-plus/dist/index.css'
import { elementPlus } from './utils/elementPlus'
import './mock' // mockjs
// import 'amfe-flexible'
;(function () {
  const docEl = document.documentElement
  docEl.style.fontSize = '14px'
})()

const app = createApp(App)

elementPlus(app)

app.use(store).use(router).mount('#app')
