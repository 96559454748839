/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/404',
    component: () => import('@/views/exception/exceptionPage.vue')
  }
]

// 生成首页路由
export const generateIndexRouter = () => {
  return [
    {
      path: '/',
      name: 'layout',
      component: () => import('@/views/layout/layoutPage.vue'),
      redirect: () => {
        return { path: '/home' }
      },
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/home/homePage.vue')
        },
        {
          path: '/solution',
          name: 'solution',
          component: () => import('@/views/solution/solutionPage.vue')
        },
        {
          path: '/product',
          name: 'product',
          component: () => import('@/views/product/productPage.vue')
        },
        {
          path: '/project',
          name: 'project',
          component: () => import('@/views/project/projectPage.vue')
        },
        {
          path: '/about',
          name: 'about',
          component: () => import('@/views/about/aboutPage.vue')
        }
      ]
    },
    {
      path: '/customer:id',
      name: 'customer',
      component: () => import('@/views/layout/components/websocketChat.vue')
    },
    {
      path: '/business:id',
      name: 'business',
      component: () => import('@/views/layout/components/websocketReply.vue')
    },
    {
      path: '/:pathMatch(.*)',
      redirect: '/404',
      hidden: true
    }
  ]
}
