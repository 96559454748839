/*
 * @Author: wzbin
 * @Date: 2022-05-25 17:00:00
 * @LastEditTime: 2022-05-25 17:00:00
 * @LastEditors: wzbin
 * @Description: In User Settings Edit
 * @FilePath: src\router\index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { generateIndexRouter, constantRouterMap } from '@/router/router.config'

const routes = [...constantRouterMap, ...generateIndexRouter()]

// createRouter 创建路由实例
const router = createRouter({
  /**
   * hash模式：createWebHashHistory，
   * history模式：createWebHistory
   */
  history: createWebHashHistory(),
  routes
})

NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 600, showSpinner: false })

// 设置title
router.beforeEach((to, from, next) => {
  // 启动进度条
  NProgress.start()

  // 设置头部
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // } else {
  //   document.title = process.env.NODE_ENV
  // }
  next()
})

router.afterEach(() => {
  // 关闭进度条
  NProgress.done()
})

// 抛出路由实例, 在 main.js 中引用
export default router
