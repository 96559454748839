/*
 * @Author: wzbin
 * @Date: 2022-05-25 17:00:00
 * @LastEditTime: 2022-05-25 17:00:00
 * @LastEditors: wzbin
 * @Description: In User Settings Edit
 * @FilePath: src\store\index.js
 */
import { createStore, createLogger } from 'vuex'
import active from './modules/active'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    active
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
