/*
 * @Author: wzbin
 * @Date: 2022-05-25 17:00:00
 * @LastEditTime: 2022-05-25 17:00:00
 * @LastEditors: wzbin
 * @Description: In User Settings Edit
 * @FilePath: src\utils\auth.js
 */

/**
 * @description: 获取Active:String
 */
export const getActive = (key) => window.sessionStorage.getItem(key)

/**
 * @description: 设置Active:String
 */
export const setActive = (key, value) =>
  window.sessionStorage.setItem(key, value)

/**
 * @description: 删除Active:String
 */
export const deleteActive = (key) => window.sessionStorage.removeItem(key)

/**
 * 获取本地图
 * @param name // 文件名 如 doc.png
 * @returns {*|string}
 */
export const getAssetsImage = (name) => {
  return new URL(`/src/assets/images/${name}`, import.meta.url).href
}

/**
 * 获取本地视频
 * @param name // 文件名 如 doc.png
 * @returns {*|string}
 */
export const getAssetsVideo = (name) => {
  return new URL(`/src/assets/video/${name}`, import.meta.url).href
}
