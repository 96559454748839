import Mock from 'mockjs'
import { getAssetsImage } from '@/utils/auth'
// get请求从config.url获取参数，post从config.body中获取参数
function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  )
}

const subtitleObj = {
  solution: [
    {
      label: '金融',
      list: [
        {
          idx: 'bo-jjfz-001',
          label: '数据中心可视化',
          value: 'solution',
          disabled: true
        },
        {
          idx: 'bo-jjfz-002',
          label: '数字化智慧园区',
          value: 'solution',
          disabled: true
        },
        {
          idx: 'bo-jjfz-003',
          label: '金融安保可视化',
          value: 'solution',
          disabled: true
        }
      ]
    },
    {
      label: '医疗',
      list: [
        {
          idx: 'bo-jjfz-004',
          label: '智慧医院',
          value: 'solution',
          disabled: true
        },
        {
          idx: 'bo-jjfz-005',
          label: '医院智能运营',
          value: 'solution',
          disabled: true
        },
        {
          idx: 'bo-jjfz-006',
          label: '医院运营可视化',
          value: 'solution',
          disabled: true
        },
        {
          idx: 'bo-jjfz-007',
          label: '医院运营集成中心',
          value: 'solution',
          disabled: true
        }
      ]
    },
    {
      label: '能源',
      list: [
        {
          disabled: true,
          idx: 'bo-jjfz-008',
          label: '智慧工厂',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-009',
          label: '智慧矿山',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-010',
          label: '数字电网',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-011',
          label: '智慧管网',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-012',
          label: '智慧变电站',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-013',
          label: '智慧加油站',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-014',
          label: '智慧综合能源',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-015',
          label: '能源智慧园区',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-016',
          label: '电网一体化运维',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-017',
          label: '智慧运营中心',
          value: 'solution'
        }
      ]
    },
    {
      label: '教育',
      list: [
        {
          disabled: true,
          idx: 'bo-jjfz-018',
          label: '智慧校园',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-019',
          label: '绿色校园',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-020',
          label: '平安校园',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-021',
          label: '智慧教研',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-022',
          label: '校园地下管网',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-023',
          label: '教育数字孪生中台',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-024',
          label: '多网融合管理平台',
          value: 'solution'
        }
      ]
    },
    {
      label: '企业',
      list: [
        {
          disabled: true,
          idx: 'bo-jjfz-025',
          label: '智慧园区',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-026',
          label: '智慧工厂',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-027',
          label: '智慧场馆',
          value: 'solution'
        },
        {
          disabled: false,
          idx: 'bo-jjfz-028',
          label: '智慧农业',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-029',
          label: '智慧钢铁',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-030',
          label: '污水处理',
          value: 'solution'
        }
      ]
    },
    {
      label: '军队',
      list: [
        {
          disabled: true,
          idx: 'bo-jjfz-031',
          label: '智慧军营',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-032',
          label: '军队运维可视化',
          value: 'solution'
        }
      ]
    },
    {
      label: '交通',
      list: [
        {
          disabled: true,
          idx: 'bo-jjfz-033',
          label: '轨道交通',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-034',
          label: '智慧地铁',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-035',
          label: '智慧港口',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-036',
          label: '智慧航运',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-037',
          label: '智慧机场',
          value: 'solution'
        }
      ]
    },
    {
      label: '应急',
      list: [
        {
          disabled: true,
          idx: 'bo-jjfz-038',
          label: '消防应急预案',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-039',
          label: '消防应急一张图',
          value: 'solution'
        }
      ]
    },
    {
      label: '公安',
      list: [
        {
          disabled: true,
          idx: 'bo-jjfz-040',
          label: '公安机房可视化',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-041',
          label: '公安架构可视化',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-042',
          label: '公安运维可视化',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-043',
          label: '监所可视化',
          value: 'solution'
        }
      ]
    },
    {
      label: '政府',
      list: [
        {
          disabled: true,
          idx: 'bo-jjfz-044',
          label: '智慧城市',
          value: 'solution'
        },
        {
          disabled: false,
          idx: 'bo-jjfz-045',
          label: '智慧社区',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-046',
          label: '产业园区',
          value: 'solution'
        },
        {
          disabled: true,
          idx: 'bo-jjfz-047',
          label: '智慧水文',
          value: 'solution'
        }
      ]
    }
  ],
  product: [
    {
      label: '数字孪生服务',
      list: [
        {
          idx: 'bo-cpfw-001',
          label: '智慧园区可视化',
          introduce: '园区数字化转型升级',
          disabled: true,
          value: 'product'
        },
        {
          idx: 'bo-cpfw-002',
          label: '智慧城市可视化',
          introduce: '全局视角掌握城市业务',
          disabled: true,
          value: 'product'
        },
        {
          idx: 'bo-cpfw-003',
          label: '数据中心可视化',
          introduce: '数据中心卓越运营',
          disabled: true,
          value: 'product'
        },
        {
          idx: 'bo-cpfw-004',
          label: 'IOC智能运营中心',
          introduce: '可视、可管、可控',
          disabled: true,
          value: 'product'
        },
        {
          idx: 'bo-cpfw-005',
          label: '数字孪生中台',
          introduce: '灵活支撑数字孪生应用',
          disabled: true,
          value: 'product'
        },
        {
          idx: 'bo-cpfw-006',
          label: '架构管理可视化',
          introduce: 'IT架构图一体化管理',
          disabled: true,
          value: 'product'
        }
      ]
    }
  ],
  about: [
    {
      label: '公司简介',
      list: [
        {
          idx: 'bo-gywm-001',
          label: '联系我们',
          disabled: true,
          value: 'about'
        }
      ]
    }
  ]
}
const relatedApp = {
  'bo-jjfz-028': [
    {
      id: Mock.Random.guid(),
      title: '行业',
      describe: '智慧农业',
      icon: getAssetsImage('app-hangye.png')
    },
    {
      id: Mock.Random.guid(),
      title: '需求',
      describe: '一图观家底，一网管全市',
      icon: getAssetsImage('app-xuqiu.png')
    },
    {
      id: Mock.Random.guid(),
      title: '设备',
      describe: '可视化大屏，pc端浏览',
      icon: getAssetsImage('app-shebei.png')
    },
    {
      id: Mock.Random.guid(),
      title: '功能',
      describe:
        '首页，产业分布，农业经营主体，科技支撑分布，生产要素展示，后台数据更改模块',
      icon: getAssetsImage('app-gongneng.png')
    }
  ],
  'bo-jjfz-045': [
    {
      id: Mock.Random.guid(),
      title: '行业',
      describe: '智慧社区，智慧乡镇',
      icon: getAssetsImage('app-hangye.png')
    },
    {
      id: Mock.Random.guid(),
      title: '需求',
      describe: '打破信息孤岛，一网统管',
      icon: getAssetsImage('app-xuqiu.png')
    },
    {
      id: Mock.Random.guid(),
      title: '设备',
      describe: '可视化大屏，pc端浏览',
      icon: getAssetsImage('app-shebei.png')
    },
    {
      id: Mock.Random.guid(),
      title: '功能',
      describe: '首页，安防管理，人员管理，消防管理，车辆管理，房屋管理',
      icon: getAssetsImage('app-gongneng.png')
    }
  ]
}

const relatedAppTemp = {
  'bo-jjfz-028': {
    title: '农业大数据平台',
    describe:
      '一图观家底，一网管全市，随着农业数据的不断汇聚，为提高业务决策效率，步欧基于智慧农业大数据平台，通过整合40余类农业农村数据，建成了“农业大数据一张图”，为农业大数据系统的综合信息查询和可视化展现提供数字化应用平台，实现对重点业务工作的可视化管理与图形化展示。',
    image: getAssetsImage('cases02.png')
  },
  'bo-jjfz-045': {
    title: '社区智能运营中心',
    describe:
      '步欧为某社区建设智慧社区运营大脑，该项目包括7大模块，从安防管理，人员管理，消防管理，车辆管理，房屋管理等7各模块，整体提升社区智能化水平，提高业务部门工作效率及集团管理效率，改善社区服务体验、提高社区用户满意度，开展增值服务运营，拓宽收益来源',
    image: getAssetsImage('solution/shequ/cases-001.jpg')
  }
}

const sceneAppObj = {
  'bo-jjfz-028': [
    {
      id: Mock.Random.guid(),
      title: '环境数据感知',
      image: getAssetsImage('solution01.png'),
      describe:
        '用数据思维看待农业资源管理，构建高效数字化生产体系，通过对数据资源进行梳理整合分析，提供生产、预测、防控等全要素管理能力。全域的智能监测数据采集通过“农业大脑”反馈农业场景应用，为园区智能生产保驾护航，提升耕种管收全链路、全流程智能化水平。',
      list: [
        {
          id: Mock.Random.guid(),
          title: '土壤数据感知',
          describe: '土壤数据实时可视化感知',
          icon: getAssetsImage('scene-turang.png')
        },
        {
          id: Mock.Random.guid(),
          title: '作物长势感知',
          describe: '作物生育期、长势分析、作物表型、苗期营养分析等可视化感知',
          icon: getAssetsImage('scene-zuowu.png')
        },
        {
          id: Mock.Random.guid(),
          title: '气候环境感知',
          describe: '空气湿度、风速、降雨量等可视化感知',
          icon: getAssetsImage('scene-qihou.png')
        }
      ]
    },
    {
      id: Mock.Random.guid(),
      title: '实时数据监测',
      image: getAssetsImage('solution02.png'),
      describe: '园区内设备运行数据的可视化监管，异常事件自动报警处置。',
      list: [
        {
          id: Mock.Random.guid(),
          title: '农业智慧生产',
          describe:
            '对基础设施、农机具等硬件系统进行智能化改造升级，构建园区高效数字化生产体系，实现农业管理数字化、无人化',
          icon: getAssetsImage('scene-nongye.png')
        },
        {
          id: Mock.Random.guid(),
          title: '园区智慧物联',
          describe:
            '深化传感器、大数据、智能识别等先进成熟技术的应用，围绕农作物生长状态及关键影响因素的监测，开展园区智慧物联建设',
          icon: getAssetsImage('scene-yuanqu.png')
        },
        {
          id: Mock.Random.guid(),
          title: '种植管理系统',
          describe: '产前评估分析，产中规范管理，产后溯源追踪',
          icon: getAssetsImage('scene-zhongzhi.png')
        }
      ]
    }
  ],
  'bo-jjfz-045': [
    {
      id: Mock.Random.guid(),
      title: '社区运营中心',
      image: getAssetsImage('solution/shequ/cases-001.jpg'),
      describe:
        '集成各业务系统数据，实现城区总体态势统一展示，并根据各种场景应用，联动安防、消防、告警、交通应用，实现多场景高智能化联动，实现城区的可视、可控、可管。',
      list: [
        {
          id: Mock.Random.guid(),
          title: '运营中心',
          describe:
            '社区实时态势全面感知,打通信息孤岛，人、地、事、物、情一图掌控、全域监测、分 析研判。',
          icon: getAssetsImage('solution/shequ/icon-001.png')
        },
        {
          id: Mock.Random.guid(),
          title: '房屋管理',
          describe: '房屋管理',
          icon: getAssetsImage('solution/shequ/icon-002.png')
        }
      ]
    },
    {
      id: Mock.Random.guid(),
      title: '综合安防管理',
      image: getAssetsImage('solution/shequ/cases-002.jpg'),
      describe: '',
      list: [
        {
          id: Mock.Random.guid(),
          title: '消防管理',
          describe:
            '将社区安防管理中的消防设备及其分布进行呈现，当产生消防告警时，系统在相应位置迅速高亮提示',
          icon: getAssetsImage('solution/shequ/icon-003.png')
        },
        {
          id: Mock.Random.guid(),
          title: '实时监控',
          describe:
            '集成视频监控、视频周界、视频巡更，进行统一管理和集中展示和安全宣传',
          icon: getAssetsImage('solution/shequ/icon-004.png')
        },
        {
          id: Mock.Random.guid(),
          title: '预警处置',
          describe:
            '设备异常，围栏拦截等异常事件三维场景联动并工单自动派发处置',
          icon: getAssetsImage('solution/shequ/icon-005.png')
        }
      ]
    }
  ]
}

export default {
  getSubtitleList: (config) => {
    const { id } = JSON.parse(config.body)
    const datas = subtitleObj[id] || []
    return {
      code: 200,
      count: datas.length,
      result: datas
    }
  },
  getSceneAppList: (config) => {
    const { id } = JSON.parse(config.body)
    const datas = sceneAppObj[id]
    return {
      code: 200,
      count: datas.length,
      result: datas
    }
  },
  getRelatedAppList: (config) => {
    const { id } = JSON.parse(config.body)
    const datas = relatedApp[id || 'bo-jjfz-028'] || []
    return {
      code: 200,
      count: datas.length,
      result: datas
    }
  },
  getRelatedAppTemp: (config) => {
    const { id } = JSON.parse(config.body)
    const datas = relatedAppTemp[id || 'bo-jjfz-028'] || []
    return {
      code: 200,
      count: datas.length,
      result: datas
    }
  }
}
