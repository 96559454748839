/*
 * @Author: wzbin
 * @Date: 2022-05-25 17:00:00
 * @LastEditTime: 2022-05-25 17:00:00
 * @LastEditors: wzbin
 * @Description: In User Settings Edit
 * @FilePath: src\mock\index.js
 */
import Mock from 'mockjs'
import homeApi from './data/home'
import commomApi from './data/common'

// 设置200-2000毫秒延时请求数据
// Mock.setup({
//   timeout: '200-2000'
// })

// 首页相关
Mock.mock(/\/home\/getHomeMenu/, 'get', homeApi.getHomeMenu)
Mock.mock(/\/home\/getHomeSubMenu/, 'get', homeApi.getHomeSubMenu)
Mock.mock(/\/home\/getHomeBanner/, 'get', homeApi.getHomeBanner)
Mock.mock(/\/home\/getHomeCases/, 'get', homeApi.getHomeCases)
Mock.mock(/\/home\/getHomeAbout/, 'get', homeApi.getHomeAbout)

// 其他相关
Mock.mock(/\/api\/getSubtitleList/, 'post', commomApi.getSubtitleList)
Mock.mock(/\/api\/getRelatedAppList/, 'post', commomApi.getRelatedAppList)
Mock.mock(/\/api\/getRelatedAppTemp/, 'post', commomApi.getRelatedAppTemp)
Mock.mock(/\/api\/getSceneAppList/, 'post', commomApi.getSceneAppList)
