/**
 * 补充：
 * Mock.Random.guid()
 * Mock.Random.domain() 随机域名
 * Mock.Random.ip() 随机IP
 * Mock.Random.url() 随机url
 * Mock.Random.province() 随机省份
 * Mock.Random.city() 随机城市
 * Mock.Random.city(true) 随机省份+城市
 * Mock.Random.csentence() 随机的中文句子
 * Mock.Random.cparagraph() 随机的中文段落
 * Mock.Random.rgba() 随机颜色
 * Mock.Random.date('yyyy-MM-dd') 随机日期
 * Mock.Random.time() 随机时间
 * Mock.Random.datetime('yyyy-MM-dd HH:mm:ss')
 * Mock.Random.cname() 随机模拟全名
 * Mock.Random.cfirst() 随机模拟姓氏
 * Mock.Random.clast() 随机模拟名字
 */

import Mock from 'mockjs'
import { getAssetsImage, getAssetsVideo } from '@/utils/auth'

// const mockList = []
// for (let i = 0; i < 5; i += 1) {
//   mockList.push(
//     Mock.mock({
//       id: Mock.Random.guid(),
//       name: Mock.Random.cname(),
//       createTime: Mock.Random.datetime('yyyy-MM-dd HH:mm:ss'),
//       description: Mock.Random.city(true)
//     })
//   )
// }
export default {
  getHomeMenu: () => {
    return {
      code: 200,
      count: 5,
      result: [
        {
          name: '首页',
          label: '首页',
          value: 'home',
          open: false,
          disabled: false,
          child: []
        },
        {
          name: '解决方案',
          label: '解决方案',
          value: 'solution',
          open: true,
          disabled: false,
          child: []
        },
        {
          name: '产品服务',
          label: '产品服务',
          value: 'product',
          open: true,
          disabled: false,
          child: [
            {
              id: Mock.Random.guid(),
              idx: 'bo-cpfw-sjcj',
              label: '数据采集',
              disabled: false,
              value: 'product'
            },
            {
              id: Mock.Random.guid(),
              idx: 'bo-cpfw-cjjm',
              label: '场景建模',
              disabled: false,
              value: 'product'
            },
            {
              id: Mock.Random.guid(),
              idx: 'bo-cpfw-xmfw',
              label: '项目服务',
              disabled: false,
              value: 'product'
            },
            {
              id: Mock.Random.guid(),
              idx: 'bo-cpfw-swxryq',
              label: '三维渲染引擎',
              disabled: false,
              value: 'product'
            }
          ]
        },
        {
          name: '项目案例',
          label: '项目案例',
          value: 'project',
          open: false,
          disabled: false,
          child: []
        },
        {
          name: '关于我们',
          label: '关于我们',
          value: 'about',
          open: false,
          disabled: false,
          child: []
        }
      ]
    }
  },
  getHomeSubMenu: () => {
    return {
      code: 200,
      count: 3,
      result: [
        {
          id: Mock.Random.guid(),
          label: '数字孪生平台',
          value: '数字孪生平台',
          content: '数字孪生、万物可视、打破数据孤岛',
          image: getAssetsImage('banner01.jpg'),
          video: getAssetsVideo('banner01-1.mp4')
        },
        {
          id: Mock.Random.guid(),
          label: '数字孪生平台',
          value: '数字孪生平台',
          color: '#253554',
          content: '数字孪生、万物可视、打破数据孤岛',
          image: getAssetsImage('banner01.jpg'),
          video: getAssetsVideo('banner01-2.mp4')
        },
        {
          id: Mock.Random.guid(),
          label: '数字孪生平台',
          value: '数字孪生平台',
          content: '数字孪生、万物可视、打破数据孤岛',
          image: getAssetsImage('banner01.jpg'),
          video: getAssetsVideo('banner01-1.mp4')
        }
      ]
    }
  },
  getHomeBanner: () => {
    return {
      code: 200,
      count: 4,
      result: [
        {
          id: Mock.Random.guid(),
          name: '智慧工厂',
          idx: 'bo-jjfz-028',
          value: 'solution',
          content:
            '实时生产全过程可视化监测，仓储物流高精度生产管控，安防隐患智能化排查，生产环境全面性保障',
          controls: [
            {
              label: '生产管理',
              value: '生产管理',
              icon: 'iconfont-shengchanguanli'
            },
            {
              label: '巡检管理',
              value: '巡检管理',
              icon: 'iconfont-xunjianguanli'
            },
            {
              label: '仓储管理',
              value: '仓储管理',
              icon: 'iconfont-cangchuguanli'
            },
            {
              label: '自动化车间',
              value: '自动化车间',
              icon: 'iconfont-zidonghuachejian'
            }
          ]
        },
        {
          id: Mock.Random.guid(),
          name: '生产管控',
          idx: 'bo-jjfz-028',
          value: 'solution',
          content: '个性化3D可视化监控平台，全面覆盖运维管理3D自动化监控领域',
          controls: [
            {
              label: '智慧农业',
              value: '智慧农业',
              icon: 'iconfont-zhihuinongye'
            },
            {
              label: '电力电网',
              value: '电力电网',
              icon: 'iconfont-dianlidianwang'
            },
            {
              label: '港口港务',
              value: '港口港务',
              icon: 'iconfont-gangkougangwu'
            },
            {
              label: '智慧工地',
              value: '智慧工地',
              icon: 'iconfont-zhihuigongdi'
            }
          ]
        },
        {
          id: Mock.Random.guid(),
          name: '智慧楼宇',
          idx: 'bo-jjfz-028',
          value: 'solution',
          content:
            '从“智慧管理”，“智慧服务”维度，打破传统的信息孤岛，为楼宇提供一体化运营管理以及多方位企业服务',
          controls: [
            {
              label: '智慧园区',
              value: '智慧园区',
              icon: 'iconfont-zhihuiyuanqu'
            },
            {
              label: '智慧楼宇',
              value: '智慧楼宇',
              icon: 'iconfont-zhihuilouyu'
            },
            {
              label: '智慧校园',
              value: '智慧校园',
              icon: 'iconfont-zhihuixiaoyuan'
            },
            {
              label: '智慧社区',
              value: '智慧社区',
              icon: 'iconfont-zhihuishequ'
            }
          ]
        },
        {
          id: Mock.Random.guid(),
          name: '智慧城市',
          idx: 'bo-jjfz-028',
          value: 'solution',
          content:
            '以全局视角掌控城市业务，打造新一代智慧城市IOC,为城市治理、经济规划、政务服务、应急指挥提供有效支撑',
          controls: [
            {
              label: '水利水文',
              value: '水利水文',
              icon: 'iconfont-shuilishuiwen'
            },
            {
              label: '能源电力',
              value: '能源电力',
              icon: 'iconfont-nengyuandianli'
            },
            {
              label: '消防安保',
              value: '消防安保',
              icon: 'iconfont-xiaofanganbao'
            },
            {
              label: '交通警务',
              value: '交通警务',
              icon: 'iconfont-jiaotongjingwu'
            }
          ]
        }
      ]
    }
  },
  getHomeCases: () => {
    return {
      code: 200,
      count: 2,
      result: [
        {
          id: Mock.Random.guid(),
          value: 'project',
          idx: '',
          active: 'item-1',
          label: '社区网格化管理智慧展示系统',
          title: '社区网格化管理智慧展示系统',
          subtitle:
            '三维网格化综治管理平台是一款基于WEBGL技术自主研发的兼容三维、二维等多种数据格式的三维社区网格化管理平台。使用实景三维模型作为地图，地形地貌真实展示，信息快速定位查找，动态处理事件，把“人、地、物、事、组织”等全部纳入网格管理，对每一网格实施精细化、主动化、可视化管理。',
          other: '查看详情',
          image: getAssetsImage('cases01.png'),
          video: ''
        },
        {
          id: Mock.Random.guid(),
          value: 'project',
          idx: '',
          active: 'item-2',
          label: '农业大数据平台',
          title: '农业大数据平台',
          subtitle:
            '一图观家底，一网管全市，随着农业数据的不断汇聚，为提高业务决策效率，步欧基于智慧农业大数据平台，通过整合40余类农业农村数据，建成了“农业大数据一张图”，为农业大数据系统的综合信息查询和可视化展现提供数字化应用平台，实现对重点业务工作的可视化管理与图形化展示。',
          other: '查看详情',
          image: getAssetsImage('cases02.png'),
          video: ''
        },
        {
          id: Mock.Random.guid(),
          value: 'project',
          idx: '',
          active: 'item-3',
          label: '智慧矿山',
          title: '智慧矿山',
          subtitle:
            '步欧科技为科瑞森重装股份有限公司提供智慧矿井可视化管控解决方案，该方案是智慧矿山的重要组成部分，是国家智能化示范煤矿建设理念的体现。快掘系统数字孪生平台，构建覆盖生产，安全，经营，多业务多系统的数字孪空间，围绕『人、机、料、法、环』搭建一体化、可视化高度融合的生产指挥调度平台，让矿下工作运营透明可视、资源调度快速有序、生产安全高效协同，提升采矿运作效率和整体产能。',
          other: '查看详情',
          image: getAssetsImage('cases04.png'),
          video: ''
        }
      ]
    }
  },
  getHomeAbout: () => {
    return {
      code: 200,
      count: 5,
      result: [
        {
          id: Mock.Random.guid(),
          label: '解决方案',
          width: '15%',
          padRight: '5%',
          lists: [
            { label: '智慧政府', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '智慧金融', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '智慧能源', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '智慧企业', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '智慧公安', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '智慧交通', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '智慧教育', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '智慧医疗', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '智慧军队', value: 'solution', idx: 'bo-jjfz-028' },
            { label: '应急预案', value: 'solution', idx: 'bo-jjfz-028' }
          ]
        },
        {
          id: Mock.Random.guid(),
          label: '产品服务',
          width: '15%',
          lists: [
            { idx: 'bo-cpfw-xmfw', label: '数据中心可视化', value: 'product' },
            { idx: 'bo-cpfw-xmfw', label: '架构管理可视化', value: 'product' },
            { idx: 'bo-cpfw-xmfw', label: '智慧城市可视化', value: 'product' },
            { idx: 'bo-cpfw-xmfw', label: '智慧园区可视化', value: 'product' },
            { idx: 'bo-cpfw-xmfw', label: '数据孪生中台', value: 'product' },
            { idx: 'bo-cpfw-xmfw', label: 'IOC智能运营中心', value: 'product' },
            { idx: 'bo-cpfw-xmfw', label: '三维渲染引擎', value: 'product' }
          ]
        },
        {
          id: Mock.Random.guid(),
          label: '项目案例',
          width: '20%',
          lists: [
            {
              label: '社区网格化管理智慧展示系统',
              value: 'project',
              idx: ''
            },
            { label: '农业大数据平台', value: 'project', idx: '' }
          ]
        },
        {
          id: Mock.Random.guid(),
          label: '关于我们',
          width: '15%',
          lists: [{ label: '关于我们', value: 'about', idx: '' }]
        },
        {
          id: Mock.Random.guid(),
          label: '联系我们',
          width: '15%',
          point: 'none',
          lists: [
            {
              icon: 'iconfont-dianhua',
              label: '电话：19927681157 （欧经理）',
              value: '19927681157'
            }
          ]
        }
      ]
    }
  }
}
