/*
 * @Author: wzbin
 * @Date: 2022-05-25 17:00:00
 * @LastEditTime: 2022-05-25 17:00:00
 * @LastEditors: wzbin
 * @Description: In User Settings Edit
 * @FilePath: src\utils\service.js
 */
import Axios from 'axios'

Axios.defaults.headers.Accept = 'application/json;charset=UTF-8'
Axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建一个axios实例
const service = Axios.create({
  baseURL: '/',
  // 请求超时时间
  timeout: 1000 * 10
})

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

// 响应拦截
service.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default service
