import {
  // 没有全局注册的建议在组件内部单独引入
  ElCard,
  ElCarousel,
  ElCarouselItem,
  ElContainer,
  ElHeader,
  ElImage,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElScrollbar,
  ElSubMenu,
  ElTabs,
  ElTabPane,
  ElTag,
  ElRow,
  ElCol,
  ElSkeleton,
  ElSkeletonItem,
  ElInfiniteScroll,
  ElLoading,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElPopover,
  ElForm,
  ElFormItem,
  ElInput
} from 'element-plus'

export const components = [
  ElCard,
  ElCarousel,
  ElCarouselItem,
  ElContainer,
  ElHeader,
  ElImage,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElScrollbar,
  ElSubMenu,
  ElTabs,
  ElTabPane,
  ElTag,
  ElRow,
  ElCol,
  ElSkeleton,
  ElSkeletonItem,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElPopover,
  ElForm,
  ElFormItem,
  ElInput
]

const plugins = [ElInfiniteScroll, ElLoading]

export const elementPlus = (app) => {
  components.forEach((component) => {
    /* eslint no-param-reassign: "error" */
    app.component(component.name, component)
  })
  plugins.forEach((plugin) => app.use(plugin))
  const options = {} // 全局配置z-index等信息
  app.config.globalProperties.$ELEMENT = options
}
