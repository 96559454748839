/*
 * @Author: wzbin
 * @Date: 2022-05-25 17:00:00
 * @LastEditTime: 2022-05-25 17:00:00
 * @LastEditors: wzbin
 * @Description: In User Settings Edit
 * @FilePath: src\store\modules\active.js
 */

import { getActive, setActive } from '@/utils/auth'
import httpService from '@/utils/service'

const state = {
  count: 1,
  menu: getActive('menu') || 'home',
  isChat: false
}

const handleStateChange = {
  updateActiveField(state, { fields, values }) {
    fields.forEach((field, i) => {
      /* eslint no-param-reassign: "error" */
      state[field] = values[i]
      setActive(field, values[i])
    })
  }
}
const actions = {
  /**
   * @method getDataUrl
   * @params id+
   * @options { url,type:GET/POST/PUT/DELETE }
   */
  async getDataUrl({ commit }, { params, options }) {
    return new Promise((resolve, reject) => {
      switch (options.type) {
        case 'GET':
          httpService
            .get(options.url, { params })
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
          break
        case 'POST':
          httpService
            .post(options.url, params)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
          break
        case 'PUT':
          httpService
            .put(options.url, params)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
          break
        case 'DELETE':
          httpService
            .delete(options.url, { params })
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
          break
        default:
          break
      }
    })
  }
}

export default {
  state,
  mutations: {
    ...handleStateChange
  },
  actions
}
